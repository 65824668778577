<template>
  <v-card class="ma-3 google-font full-size-font" v-if="jobInfo">
    <v-card-title primary-title>
      <div class="headline primary--text">
        Debug: {{ jobId }}
      </div>
      <CopyableText
        icon="content_copy"
        :copyText="jobId"
        class="ml-2"
        >(copy)</CopyableText
      >
    </v-card-title>
    <v-card-text>
      <div class="copyable-fields">
        <div>Name:</div>
        <div>{{ jobInfo.job.user_provided_name }}</div>
        <div>Created:</div>
        <div>{{ jobInfo.job.created | formatTime }}</div>
        <div>Owner:</div>
        <div>
          <router-link
            :to="{ name: 'users-edit', params: { id: jobInfo.job.owner_id }}"
            target="_blank"
            class="mr-2"
          >{{ jobInfo.job.owner_id }}</router-link>
          <CopyableText
            icon="content_copy"
            :copyText="jobInfo.job.owner_id"
            >(copy)</CopyableText
          >
        </div>
        <div>Status</div>
        <div>
          <JobResultsChip :jobStatus="jobInfo.job.job_status" />
        </div>
        <div class="copyable-fields-col">Email:</div>
        <div>
          <CopyableText
            icon="content_copy"
            :copyText="jobInfo.results_email_address"
            >{{ jobInfo.results_email_address }}</CopyableText
          >
        </div>
        <div class="copyable-fields-col">Results URL:</div>
        <div>
          <CopyableText
            icon="content_copy"
            :copyText="jobInfo.job.results_url"
            >{{ jobInfo.job.results_url }}</CopyableText
          >
        </div>
        <div class="copyable-fields-col">Input Files JSON:</div>
        <div>
          <a :href="getS3DashboardLink(parseS3Uri(jobInfo.input_file_json_path))" target="_blank" class="mr-1">{{ jobInfo.input_file_json_path }}</a>
          <CopyableText
            icon="content_copy"
            :copyText="jobInfo.input_file_json_path"
            >(copy)</CopyableText
          >
        </div>
        <div class="copyable-fields-col">Outputs:</div>
        <div>
          <a :href="getS3DashboardLink(parseS3Uri(jobInfo.output_file_prefix))" target="_blank" class="mr-1">{{ jobInfo.output_file_prefix }}</a>
          <CopyableText
            icon="content_copy"
            :copyText="jobInfo.output_file_prefix"
            >(copy)</CopyableText
          >
        </div>
        <div class="copyable-fields-col">Internal Outputs:</div>
        <div>
          <a :href="getS3DashboardLink(parseS3Uri(jobInfo.internal_output_file_prefix))" target="_blank" class="mr-1">{{ jobInfo.internal_output_file_prefix }}</a>
          <CopyableText
            icon="content_copy"
            :copyText="jobInfo.internal_output_file_prefix"
            >(copy)</CopyableText
          >
        </div>
        <div class="copyable-fields-col">Lab Data:</div>
        <div>
          <a :href="getS3DashboardLink(parseS3Uri(jobInfo.lab_file_prefix))" target="_blank" class="mr-1">{{ jobInfo.lab_file_prefix }}</a>
          <CopyableText
            icon="content_copy"
            :copyText="jobInfo.lab_file_prefix"
            >(copy)</CopyableText
          >
        </div>
        <div class="copyable-fields-col">User Provided Metadata:</div>
        <div v-if="jobInfo.metadata_file_json_path">
          <a :href="getS3DashboardLink(parseS3Uri(jobInfo.metadata_file_json_path))" target="_blank" class="mr-1">{{ jobInfo.metadata_file_json_path }}</a>
          <CopyableText
            icon="content_copy"
            :copyText="jobInfo.metadata_file_json_path"
            >(copy)</CopyableText
          >
        </div>
        <div v-else>
          No metadata provided
        </div>
      </div>
      <div class="my-3">
        <v-btn :to="{ name: 'jobs-review', params: { jobId: jobInfo.job.id } }" class="ml-0">
          Review Page
        </v-btn>
      </div>
      <div class="mt-4">Batch runs:</div>
      <v-data-table
        :headers="[
          { text: 'Batch Job ID', value: 'aws_batch_job_id' },
          { text: 'Created', value: 'created_at' },
          { text: 'Status', value: 'status' },
          { text: '', sortable: false },
        ]"
        :items="jobInfo.batch_jobs"
        :pagination.sync="defaultPagination"
        :rows-per-page-items="rowsPerPageItems"
      >
        <template v-slot:items="props">
          <td>{{ props.item.aws_batch_job_id }}</td>
          <td>{{ props.item.created_at | formatTime }}</td>
          <td>
            <BatchJobStatusChip
              v-if="props.item.status"
              :status="props.item.status"
            ></BatchJobStatusChip>
          </td>
          <td>
            <v-btn
              flat
              target="_blank"
              :href="getBatchDashboardLink(props.item.aws_batch_job_id, props.item.aws_region)"
              >AWS</v-btn
            >
            <v-btn
              flat
              target="_blank"
              :href="getBatchLogsLink(props.item.log_stream_name, props.item.aws_region)"
              >Logs</v-btn
            >
            <v-btn
              color="error"
              v-on:click="cancelBatchJob(props.item.aws_batch_job_id)"
              v-if="
                props.item.status !== 'FAILED' &&
                props.item.status !== 'SUCCEEDED'
              "
              >Cancel</v-btn
            >
          </td>
        </template>
      </v-data-table>
      <div class="mt-4">Nextflow Tasks</div>
      <NextflowTaskTable :jobId="jobId" :manager-batch-jobs="jobInfo.batch_jobs" v-if="jobInfo"></NextflowTaskTable>
      <v-progress-circular
        indeterminate
        :width="2"
        color="black"
        class="ma-3"
        v-else
      ></v-progress-circular>
      <v-expansion-panel class="my-4">
        <v-expansion-panel-content>
          <template v-slot:header>
            <div>Run Locally</div>
          </template>
          <v-card>
            <v-card-text
              >Command:
              <CopyableText icon="content_copy" :copyText="jobInfo.cmd"
                ><code>{{ jobInfo.cmd }}</code></CopyableText
              ></v-card-text
            >
          </v-card>
        </v-expansion-panel-content>
        <v-expansion-panel-content>
          <template v-slot:header>
            <div>Modify Platform/Database/Other Options</div>
          </template>
          <v-card>
            <v-card-text>
              <div class="red--text font-weight-bold mb-2">
                NOTE: there is no backup if you overwrite these, and you must
                click "Save" for them to persist.
              </div>
              <div>Current Run Options:</div>
              <CopyableText
                icon="content_copy"
                :copyText="jobInfo.run_options"
                ><code>{{
                  JSON.stringify(JSON.parse(jobInfo.run_options), null, 2)
                }}</code></CopyableText
              >
              <div>
                Set new run options (possible values found
                <a
                  href="https://gitlab.com/bugseq/goliath/-/blob/49e4dd88b2d1f60914fc9bb5a2d026c25da5c493/backend/app/app/models/process.py"
                  target="_blank"
                  >here</a
                >):
              </div>
              <v-text-field
                box
                type="text"
                v-model="runOptions"
                label="Run options"
                prepend-inner-icon="code"
                :error-messages="runOptionsErrors"
              ></v-text-field>
              <v-btn
                class="mx-10"
                v-on:click="setRunOptions(jobInfo.job.id)"
              >
                <v-icon>save</v-icon> Save
              </v-btn>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
        <v-expansion-panel-content>
          <template v-slot:header>
            <div>Input Files</div>
          </template>
          <v-card>
            <v-card-text>
              <v-btn
                class="mx-10"
                v-on:click="getInputs()"
                v-if="!inputs"
                :disabled="inputsLoading"
              >
                <v-icon>gavel</v-icon> Get Inputs
              </v-btn>
              <div v-else>
                <v-data-table
                  :headers="inputsTableHeaders"
                  :pagination.sync="inputsTableDefaultPagination"
                  :items="inputs"
                  class="elevation-1"
                >
                  <template v-slot:items="props">
                    <td>{{ props.item.filename }}</td>
                    <td>
                      <a
                        :href="getS3DashboardLink({bucket: props.item.s3_bucket, prefix: props.item.s3_key})"
                        class="mr-2"
                        target="_blank">
                          s3://{{ props.item.s3_bucket }}/{{ props.item.s3_key }}
                      </a>
                      <CopyableText
                        :copyText="'s3://' + props.item.s3_bucket + '/' + props.item.s3_key"
                        >(copy)</CopyableText
                      >
                    </td>
                    <td>{{ props.item.s3_size_bytes | formatSize }}</td>
                    <td>
                      <div v-if="!props.item.s3_is_in_inaccessible_storage_class">
                        <v-icon medium color="green">done</v-icon>
                      </div>
                      <div v-else-if="props.item.s3_is_restored">
                        <v-icon medium color="green">done</v-icon>
                      </div>
                      <div v-else-if="props.item.s3_restore_in_progress">
                        Restoring (Tier: {{ props.item.s3_restore_tier }}, started: {{ props.item.s3_restore_request_date | formatTime }})
                      </div>
                      <div v-else>
                        <v-icon medium color="red">error</v-icon>
                      </div>
                    </td>
                  </template>
                </v-data-table>
                <div v-if="anyInputsNeedRestore()" class="my-4">Some inputs must be restored before they can be used: 
                  <v-btn class="ml-4" v-on:click="restoreInputs()">
                    <v-icon class="mr-2">restore</v-icon> Restore
                  </v-btn>
                </div>
                <div class="my-4">Download a script to pull all inputs:
                  <v-btn class="ml-4" v-on:click="getPullInputsScript()">
                    <v-icon class="mr-2">file_download</v-icon> Download
                  </v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <div>Run with custom branch or sha:</div>
      <v-text-field
        name="Revision"
        box
        type="text"
        v-model="revision"
        v-validate="{ required: false, regex: /^[\w\-_/.]+$/ }"
        :error-messages="errors.first('Revision')"
        label="BugSeq nextflow revision (can be branch or git sha, defaults to production)"
        prepend-inner-icon="code"
      ></v-text-field>
      <div>
        <v-btn
          class="mx-0"
          v-on:click="run(jobInfo.job.id)"
          :disabled="errors.first('Revision')"
        >
          <v-icon class="mr-2">play_arrow</v-icon> Run
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
  <v-card class="ma-3 google-font full-size-font" v-else>
    <v-progress-circular
      indeterminate
      :width="2"
      color="black"
      class="ma-3"
    ></v-progress-circular>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import {
  dispatchGetFileLink,
  dispatchCancelBatchJob,
  dispatchRunJob,
  dispatchUpdateJobRun,
  dispatchGetAdminJobInfo,
  dispatchGetAdminJobInputs,
  dispatchAdminRestoreJobInputs,
} from "@bugseq-site/admin/src/store/modules/api/actions";
import { components } from "@bugseq-site/admin/src/lib/api/api";
import {
  formatSize,
  formatTime,
} from "@bugseq-site/shared/src/lib/utils";
import {
  getBatchDashboardLink,
  getBatchLogsLink,
  getS3DashboardLink,
  parseS3Uri,
} from "@bugseq-site/admin/src/lib/utils";
import CopyableText from "@bugseq-site/shared/src/components/CopyableText.vue";
import JobResultsChip from "@bugseq-site/shared/src/components/JobResultsChip.vue";
import BatchJobStatusChip from "@bugseq-site/admin/src/components/BatchJobStatusChip.vue";
import NextflowTaskTable from "@bugseq-site/admin/src/components/NextflowTaskTable.vue";

const ComponentProps = Vue.extend({
  props: {
    jobId: {
      type: String,
    },
  },
});

@Component({
  filters: {
    formatSize,
    formatTime,
  },
  components: {
    CopyableText,
    JobResultsChip,
    BatchJobStatusChip,
    NextflowTaskTable,
  },
  methods: {
    getBatchDashboardLink,
    getBatchLogsLink,
    getS3DashboardLink,
    parseS3Uri,
  },
})
export default class JobDebug extends ComponentProps {
  public jobInfo: components["schemas"]["JobRunAdminResponse"] | null = null;
  public revision = null;
  public runOptions = null;
  public runOptionsErrors: string[] = [];
  public inputs: components["schemas"]["AdminInputResponse"][] | null = null;
  public inputsLoading: boolean = false;
  public defaultPagination = {
    descending: true,
    sortBy: "created_at",
    rowsPerPage: 1,
  };
  public rowsPerPageItems = [1, 5, -1];

  public inputsTableHeaders = [
     {
       text: 'Filename',
       align: 'left',
       sortable: true,
       value: 'filename'
     },
     { text: 'S3 URI', value: 's3_bucket' },
     { text: 'Size', value: 's3_size_bytes' },
     { text: 'Restore Status', value: 's3_is_in_inaccessible_storage_class' },
  ]
  public inputsTableDefaultPagination = {
    descending: false,
    sortBy: "filename",
    rowsPerPage: 10,
  }

  public async mounted() {
    const jobInfo = await dispatchGetAdminJobInfo(this.$store, {
      jobId: this.jobId,
    });
    this.jobInfo = jobInfo!;
  }

  public run(id) {
    const params: components["schemas"]["RunJobRunRequest"] = {};
    if (this.revision) {
      params.cascade_revision = this.revision;
    }
    dispatchRunJob(this.$store, {
      id,
      params
    });
  }

  public setRunOptions(id) {
    if (!this.runOptions) {
      throw new Error("cant have null runOptions");
    }

    // do some sanity checks
    let parsed = {}
    try {
      parsed = JSON.parse(this.runOptions)
    } catch (e) {
      this.runOptionsErrors = ["Input does not appear to be valid JSON"]
      return
    }

    if (!("platform" in parsed)) {
      this.runOptionsErrors = ["Input does not appear valid. Should be JSON with at least 'platform' key."]
      return
    }

    this.runOptionsErrors = []

    dispatchUpdateJobRun(this.$store, {
      id,
      jobRun: { run_options: this.runOptions! },
    });
  }

  public async getInputs() {
    this.inputsLoading = true
    this.inputs = await dispatchGetAdminJobInputs(this.$store, {
      jobId: this.jobInfo!.job.id,
    });
    this.inputsLoading = false
  }

  public anyInputsNeedRestore() {
    return this.inputs!.some(input => input.s3_is_in_inaccessible_storage_class && !input.s3_restore_in_progress)
  }

  public async restoreInputs() {
    dispatchAdminRestoreJobInputs(this.$store, {
      jobId: this.jobInfo!.job.id,
    });
  }

  public getPullInputsScript() {
    const pullCmds: string[] = [];
    for (const input of this.inputs!) {
      pullCmds.push(`aws s3 cp 's3://${input.s3_bucket}/${input.s3_key}' '${input.filename}'`);
    }
    const pullScript = "#!/bin/bash\n\nset -euxo pipefail\n\n" + pullCmds.join("\n");

    // https://stackoverflow.com/a/18197341
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(pullScript));
    element.setAttribute('download', `pull-job-inputs-${this.jobInfo!.job.id}.sh`);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public async openInNewTab(filename) {
    const fileLink = await dispatchGetFileLink(this.$store, {
      jobId: this.jobInfo!.job.id,
      filename,
    });
    const newWindow = window.open(fileLink, "_blank");
    if (newWindow) {
      newWindow.focus();
    }
  }

  public cancelBatchJob(awsBatchJobId) {
    dispatchCancelBatchJob(this.$store, { awsBatchJobId });
  }
}
</script>

<style scoped>
.copyable-fields {
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: min-content;
  grid-gap: 2px 20px;
}

.copyable-fields-col {
  white-space: nowrap;
}
</style>
